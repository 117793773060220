import { useRouter } from 'next/router'
import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { routes } from '@shared/constants/routes'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { Button } from '@shipt/design-system-buttons'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'
import { Display, Body } from '@shipt/design-system-typography'
import { Box } from '@shipt/design-system-layouts'

type Props = {
  text: string
  subText?: string
  hideButton?: boolean
  buttonText?: string
  buttonAction?: () => void
  imageInfo?: {
    src: string
    alt: string
  }
  emptyStateRoute?: string
  className?: string
}

export const EmptyState = ({
  text,
  className,
  subText = '',
  hideButton = false,
  buttonText = 'Continue shopping',
  buttonAction,
  imageInfo = {
    src: objectStorageUrl('emptyStateBox.webp'),
    alt: 'Empty box',
  },
  emptyStateRoute = routes.SEARCH.url,
}: Props) => {
  const router = useRouter()
  const { src, alt } = imageInfo

  const handleButtonClick = () =>
    buttonAction ? buttonAction() : router.push(emptyStateRoute)

  return (
    <EmptyStateContainer className={className}>
      <EmptyStateImage
        src={src}
        alt={alt}
        width={375}
        height={236}
        style={{ objectFit: 'contain' }}
      />
      <HeaderText size="sm" as="h2">
        {text}
      </HeaderText>
      <Box marginBottom="lg">
        <SubText align="center" variant="secondary">
          {subText}
        </SubText>
      </Box>
      {!hideButton && (
        <Button fullWidth onClick={handleButtonClick}>
          {buttonText}
        </Button>
      )}
    </EmptyStateContainer>
  )
}

const EmptyStateContainer = styled.div`
  text-align: center;
  display: flex;
  max-width: 21.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;

  @media ${screenSizes.tablet} {
    margin: 2rem auto;
  }
`

const EmptyStateImage = styled(Image)`
  margin-bottom: 1rem;
`

const HeaderText = styled(Display)`
  margin-bottom: 0.5rem;
`
const SubText = styled(Body)`
  max-width: 21.5rem;
`
